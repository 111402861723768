/* FullCalendarStyles.css */

/* Increase the height of the time slots */
.fc-timegrid-slot {
    height: 40px !important; /* Adjust the height as needed */

}

/* Optional: Adjust the font size of the time slots */
.fc-timegrid-slot-label {
    font-size: 14px; /* Adjust the font size as needed */
}

/* Optional: Adjust the font size of event titles */
.fc-event-title {
    font-size: 12px; /* Adjust the font size as needed */
}

/* Optional: Adjust the header font size */
.fc-toolbar-title {
    font-size: 18px; /* Adjust the font size as needed */
}

/* Optional: Adjust the width of the columns in the week view */
.fc-col-header-cell {
    width: 100px !important; /* Adjust the width as needed */
}
