/* Font Family */
/* Scss Document */
/* Box Shadow */
/* Transition */
/* Transform Rotate */
/* Width Calc */
/*  Opacity Color */
/* Background Opacity Color */
/* Border Color */
/* Animation Key Frame */
/* translate3d */
/* translate */
/* Background Image */
/* text shadow */
html,
body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}


/* body{ background: url(./background.jpg) no-repeat ; background-size: cover; height: 100vh;} */

.yellow-btn-new {
  background: #ffb546;
  border-radius: 50px;
  height: 24px;
  width: 24px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  vertical-align: middle;
  text-align: center;
  margin-left: 8px;
}

.info-box{ padding: 10px; background: #e1f0fe; margin-bottom: 10px;}


/* body {
  background: url("../images/NewActivityImages/background.jpg") fixed;
  background-size: 100% 100%;
}  */

.container-fluid {
  max-width: 80%;
}

.btn-outline {
  border: none;
  box-shadow: 1px 2px 2px #242424c9;
  font-size: 16px;
  padding: 0px 5px;
  height: 20px;
  float: right;
  line-height: 20px;
  margin-right: 5px;
}

header .navbar {
  background: #011c2d;
}
header .navbar .navbar-brand {
  color: #d6f0fd !important;
}
header .navbar .nav-right .burg-menu {
  font-size: 30px;
  color: #d6f0fd;
  vertical-align: middle;
  margin-left: 30px;
}
header .navbar .nav-right button {
  border-radius: 50px;
  padding: 5px 15px;
  background: #d6f0fd;
  color: #000;
  font-size: 16px;
  position: relative;
  z-index: 9999 !important;
  width: 138px;
}
header .navbar .nav-right .dropdown-menu.show {
  background: #ebf8ff;
  margin-top: -20px;
  border-radius: 20px;
  width: 80px !important;
  padding: 30px 15px 15px 15px;
  font-size: 12px;
}
header .navbar .nav-right .dropdown-menu.show a {
  color: #011c2d;
  text-decoration: none;
}
header .navbar .nav-right .dropdown-menu.show a:hover {
  opacity: 0.7;
}

footer {
  background: #d9d9d9;
  padding: 30px 0;
}
footer .footer-logo {
  width: 65px;
  filter: brightness(0) invert(1);
  margin-bottom: 40px;
}
footer .copyright p {
  margin: 0;
  color: #687b96;
}
footer .social-media {
  display: flex;
  justify-content: flex-end;
}
footer .social-media li {
  list-style-type: none;
  margin-right: 10px;
}
footer .social-media li a {
  color: #4643d3;
  font-size: 20px;
}
footer .social-media li a:hover {
  opacity: 0.8;
}

html {
  scroll-behavior: smooth;
}

main {
  padding: 30px 0;
}

/* .show-code-popp .modal-header {
  background: #011c2d;
  color: #fff;
}
.show-code-popp .modal-header .btn-close-new {
  background: #e04f5f;
  color: #fff;
  border-radius: 50px;
  opacity: 100;
  padding: 0 5px;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 20px;
}
.show-code-popp .modal-header .btn-link {
  background: #d6f0fd;
  color: #000;
  border-radius: 50px;
  opacity: 100;
  padding: 0 5px;
  border: none;
  width: 30px;
  height: 30px;
  font-size: 16px;
  margin-right: 10px;
}
.show-code-popp .modal-header .btn-resize {
  background: transparent;
  border: none;
  margin-right: 10px;
}
.show-code-popp .modal-header .btn-resize img {
  height: 23px;
}
.show-code-popp .modal-header button:hover {
  opacity: 0.7;
} */

.plug-board {
  border-left: 20px solid #76c454;
  width: 85%;
  margin-bottom: 10px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
}
.plug-board input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}
.plug-board input:focus {
  border: none;
}
.plug-board .plug-board-top {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 4px;
}
.plug-board .plug-board-top::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: -10px;
  border-radius: 2px;
}
.plug-board .plug-board-top::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}
.plug-board .plug {
  min-height: 30px;
}
.plug-board .plug-board-bottom {
  background: #76c454;
  min-height: 30px;
  width: 50%;
  position: relative;
  padding: 5px;
}
.plug-board .plug-board-bottom::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 8px;
  border-radius: 2px;
}
.plug-board .plug-board-bottom::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: -10px;
  border-radius: 2px;
}
.plug-board .pink-span {
  background: #674394;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 5px;
}
.plug-board .yellow-span {
  background: #ffb546;
  display: inline-block;
  padding: 2px 3px;
  border-radius: 5px;
}

.pin {
  background: #fff176;
  height: 30px;
  position: relative;
  padding: 2px;
  font-family: "Courier New", monospace;
  font-size: 16px;
  width: 100%;
  font-weight: normal;
  color: #000000;
  font-style: normal;
}
.pin input {
  display: inline-block;
  width: 58px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}
.pin input:focus {
  border: none;
}
.pin::before {
  content: "";
  position: absolute;
  width: 22px;
  height: 11px;
  background: #fff;
  top: -3px;
  left: 8px;
  border-radius: 2px;
}
.pin::after {
  content: "";
  position: absolute;
  width: 17.5px;
  height: 10px;
  background: #fff176;
  bottom: -7px;
  left: 10px;
  border-radius: 2px;
  z-index: 2;
}


.work-space {
  border-left: 10px solid #7ac5ea;
  width: 85%;
  margin-bottom: 2px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  position: relative;
  z-index: 1;
}
.work-space .plug-board-top {
  background: #7ac5ea;
  min-height: 30px;
  position: relative;
  padding: 4px;
  border-bottom: 10px solid #011c2d;
}
.work-space .plug-board-top .function {
  background: #011c2d;
  width: 116px;
  height: 23px;
  color: #7ac5ea;
  padding: 3px;
  text-align: center;
  float: right;
  margin-right: -4px;
}
.work-space .plug-board-top::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 2px;
  border-radius: 2px;
}
.work-space .plug-board-top::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #011c2d;
  bottom: -15px;
  left: 25px;
  border-radius: 2px;
}
.work-space .plug {
  min-height: 30px;
}
.work-space .plug .plug-blue {
  border-left: 15px solid #011c2d;
  min-height: 120px;
}
.work-space .plug-board-bottom {
  background: #7ac5ea;
  min-height: 30px;
  width: 60%;
  position: relative;
  padding: 0;
}
.work-space .plug-board-bottom::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 25px;
  border-radius: 2px;
}
.work-space .plug-board-bottom::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #7ac5ea;
  bottom: -5px;
  left: 2px;
  border-radius: 2px;
}
.work-space .plug-board-bottom .bottom-blue {
  background: #011c2d;
  width: 150px;
  height: 20px;
}

.pin2 {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 5px 10px 5px 25px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  width: 85%;
}
.pin2 input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}
.pin2 input:focus {
  border: none;
}
.pin2::before {
  content: "";
  position: absolute;
  width: 17px;
  height: 7px;
  background: #fff;
  top: -2px;
  left: 11px;
  border-radius: 2px;
}
.pin2::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}

.choose-code-design {
  max-width: 250px;
  margin-bottom: 20px;
}
.choose-code-design .btn-outline-primary {
  color: #d6f0fd;
  background-color: #001325;
  border-color: #001325;
}
.choose-code-design .btn-check:active + .btn-outline-primary,
.choose-code-design .btn-check:checked + .btn-outline-primary,
.choose-code-design .btn-outline-primary.active,
.choose-code-design .btn-outline-primary.dropdown-toggle.show,
.choose-code-design .btn-outline-primary:active {
  color: #011c2d;
  background-color: #d6f0fd;
  border-color: #001325;
}
.choose-code-design .btn-check:active + .btn-outline-primary:focus,
.choose-code-design .btn-check:checked + .btn-outline-primary:focus,
.choose-code-design .btn-outline-primary.active:focus,
.choose-code-design .btn-outline-primary.dropdown-toggle.show:focus,
.choose-code-design .btn-outline-primary:active:focus {
  box-shadow: none;
}
.choose-code-design .btn-outline-primary:focus {
  box-shadow: none;
}

.maths-box {
  background: #ffb546;
  min-height: 30px;
  position: relative;
  padding: 8px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}
.maths-box input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}
.maths-box input:focus {
  border: none;
}

.variable-pin {
  background: #76c454;
  min-height: 30px;
  position: relative;
  padding: 5px 10px 5px 25px;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  width: 85%;
}
.variable-pin input {
  display: inline-block;
  width: 18px;
  height: 16px;
  text-align: center;
  border-radius: 2px;
  border: none;
}
.variable-pin input:focus {
  border: none;
}
.variable-pin::before {
  content: "";
  position: absolute;
  width: 22.5px;
  height: 7px;
  background: #fff;
  top: -3px;
  left: -6px;
  border-radius: 2px;
}
.variable-pin::after {
  content: "";
  position: absolute;
  width: 17px;
  height: 10px;
  background: #76c454;
  bottom: -5px;
  left: 10px;
  border-radius: 2px;
}

.btmob {
  background: linear-gradient(to right, #ee0979, white);
  height: 50%;
  width: 90%;
  font-style: italic;
}
.pic {
  height: 350px;
  width: 200px;
}
.btmob:hover {
  background: linear-gradient(to right, #5ff53a, white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* changed */
.mr-2 {
  margin-right: 70rem;
}
.mr-3 {
  margin-right: 1000rem !important;
  padding-right: 10rem;
}

.p-3 {
  padding: 4px !important;
}
.w175 {
  width: 180px !important;
}

/* changed */
.mt-8 {
  margin-top: 10rem;
}

.fontq {
  font-style: oblique;
}

.mt-1 {
  margin: 4rem !important;
}
.rm {
  right: 2rem;
}
.move-left {
  width: 100px;
  height: 60px;
  transition: margin-left 0.5s ease; /* CSS transition for smooth movement */
}
.btmob2 {
  background: linear-gradient(to right, #c7fafb, white);
  height: 70%;
  width: 60%;
  font-style: italic;
}
.btmob2:hover {
  background: linear-gradient(to right, #edf416, #f6f7ee);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.runbttn {
  background: #001325;
  font-size: 18px;
  color: white;
  text-align: center !important;
}

/* CSS */
.button-86 {
  all: unset;
  width: 100px;
  height: 30px;
  font-size: 16px;
  background: transparent;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-86::after,
.button-86::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all 0.4s;
}

.button-86::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.button-86::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.button-86:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.button-86:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.button-86:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}
.me-1 {
  margin-top: -20px;
}
.button-gap {
  margin-top: 20px;
}
.button-gap-t {
  margin-bottom: -100px;
}
.button-gap-2 {
  margin-top: -20pt !important;
}

.font-1 {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: red;
  font-size: xx-large;
}

.button-81 {
  height: 30%;
  width: 90px !important;
  font-size: 14px !important;
  display: inline-block !important;
  margin-right: 10px !important;
  border-radius: 30px;
  margin-top: 120px !important;
}

.button-81:hover {
  background-color: #1e293b;
  color: #fff;
}

@media (min-width: 768px) {
  .button-81 {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}
.questionfontsize {
  font-size: x-large;
  font-weight: 700;
  color: red;
  font-family: aclonica;
}
/* Arcade-style text */
.arcade-text {
  font-family: "Press Start 2P", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #c379c3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.arcade-text2 {
  font-family: "Press Start 2P", sans-serif;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: rgb(208, 8, 8);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.col-6{  width: 30%;}
.col-9 {
  width: 70%;
  background-color: #ffffff; /* Background color for the right div */
  display: flex;
  flex-direction: column;
  /* border: 2px solid rgb(139, 102, 192); */
}

/* Make the right pane match the height of the left pane */
.col-md-3,
.col-9 {
  align-self: stretch;
}
.header {
  background-color: black;
  color: white;
  font-weight: bold;
  border-left: 10px solid #000000;
  margin: 0;
  font-size: 15px;
}
.rightheading {
  font-weight: bold;
  color: rgb(139, 102, 192);
  padding: 1%;
}

.rightpaneul {
  font-weight: bold;
  color: rgb(32, 4, 34);
  padding: 1%;
}

.custom-row {
  --bs-gutter-x: 0 !important; /* Set gutter width to 0, !important ensures it overrides Bootstrap */
}
.design-vew2 {
  background: url("../images/NewActivityImages/mobileoverlay.png");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
}
.design-vew22 {
  background: url("../images/NewActivityImages/mobile.png");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: cover;
  padding: 50px 20px;
  margin-top: 15px;
}

.sucesstext {
  font-size: l;
  color: #d6f0fd;
}

.Textsiz {
  font-size: larger;
}
.md--10 {
  margin-bottom: 3px !important;
}
.whiteboard {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for depth */
}
.container {
  display: flex;
  flex-direction: column;
}
.react-calenderdate {
  background-color: #76c454;
}
.Acitivityon {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: xx-large;
  color: black;
  background: #e7f194;
  text-align: center;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: 50vh;
}
/* Dropdown container */
#languageDropdownContainer {
  display: inline-block;
  margin: 10px;
}

/* Label styling */
label {
  font-weight: bold;
  margin-right: 10px;
}

/* Dropdown select element */
#languageDropdown {
  padding: 8px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
}

/* Dropdown options */
#languageDropdown option {
  font-size: 15px;
}

/* Selected option style */
#languageDropdown option:checked {
  background-color: #007bff;
  color: #fff;
}

/* Selected option style on hover */
#languageDropdown option:checked:hover {
  background-color: #0056b3;
}

/* Style the container when an option is selected */
#languageDropdownContainer p {
  margin-top: 10px;
  font-weight: bold;
  color: #007bff;
}
/* Styles for a small box */
.small-box {
  width: 150px; /* Adjust the width as needed */
  height: 100px !important; /* Adjust the height as needed */
  background: linear-gradient(
    90deg,
    rgba(255, 250, 250, 1) 0%,
    rgba(78, 9, 121, 1) 35%,
    rgba(142, 255, 0, 1) 100%
  );
  border: 2px
    linear-gradient(
      90deg,
      rgba(255, 250, 250, 1) 0%,
      rgba(78, 9, 121, 1) 35%,
      rgba(142, 255, 0, 1) 100%
    ); /* Border color and thickness */
  border-radius: 20px; /* Border radius for rounded corners */
  color: #ffffff; /* Text color */
  text-align: center; /* Center text horizontally */
  line-height: 100px; /* Center text vertically */
  font-size: 35px; /* Font size */
  margin-left: 2rem !important;
  margin-top: 30px;
}

/* Hover effect */
.small-box:hover {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}
.HeaderComments {
  font-size: medium !important;
  color: black;
}
.small-box1 {
  /* background: url("../images/NewActivityImages/20492950.jpg"); */
  border-radius: 20px; /* Border radius for rounded corners */
  color: white; /* Text color */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center; /* Center text horizontally */
  line-height: 100px; /* Center text vertically */
  font-size: 20px; /* Font size */
  margin-left: 0rem !important;
  margin-top: 20px !important;
  text-align: center !important;
}
.inputFieldstyle {
  margin-top: 5rem;
  width: 80px;
}
.boxNine {
  width: 200px; /* Set the width of the box */
  height: 100px; /* Set the height of the box */
  background-color: #d39cae; /* Set the background color */
  color: #fff; /* Set the text color */
  padding: 20px; /* Add padding inside the box */
  border: 1px solid #ccc; /* Add a border with a color */
  border-radius: 10px; /* Add rounded corners */
  text-align: center; /* Center the text horizontally */
}

.boxSandK {
  width: 80px;
  height: 40px;
  background-color: #ebe1a2;
  text-align: center;
}
.boxSandK:hover {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
}
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:200");

/* body  {
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
  background-size:cover;
        -webkit-animation: slidein 100s;
        animation: slidein 100s;

        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;

        -webkit-animation-direction: alternate;
        animation-direction: alternate;              
} */

@-webkit-keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

@keyframes slidein {
  from {
    background-position: top;
    background-size: 3000px;
  }
  to {
    background-position: -100px 0px;
    background-size: 2750px;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(75, 75, 250, 0.3);
  border-radius: 3px;
}
.center h1 {
  text-align: center;
  color: white;
  font-family: "Source Code Pro", monospace;
  text-transform: uppercase;
}
.design-vew4 {
  background: url("../images/NewActivityImages/bg7.jpg");
  width: 100%;
  max-width: 257px;
  height: 525px;
  background-size: 100%;
  padding: 50px 20px;
  border-radius: 3rem;
}
.checkInput {
  border-color: #ff9f43;
  background: #fff;
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 60%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
  height: 40px;
}
.dropdownoutfit {
  text-transform: uppercase;
  background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #000000;
}
.row {
  display: flex;
}

.col-md-3 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  box-sizing: border-box;
  padding: 0 15px;
  border: 1px solid #ccc; /* Add border style here */
}

/* Additional styling for the content within the columns (optional) */
.col-md-3 div {
  padding: 6px; /* Add padding to the div content (adjust as needed) */
}
.mt-40 {
  margin-top: 20rem !important;
}

.buttonsession {
  height: 50px;
  width: 80px;
  color: black;
  background-color: white;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 10px;
}
.buttonsession:hover {
  background-color: red;
}
.buttonsession1 {
  height: 80px;
  width: 80px;
  border: 1px solid #007bff;
  background-color: #e8e5d5;
  color: rgb(9, 9, 9);
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 20px;
}

.activitySixBtnImg {
  width: 79px;
  height: 79px;
  border-radius: 5px;
}
.appLabSession10 {
  height: 121.5%;
  width: 111%;
  border-radius: 30px;
  margin-top: -45px;
  margin-left: -11px;
}

.buttonsessionActivity3 {
  height: 95px;
  width: 85px;
  border: 1px solid #007bff;
  color: wheat;
  margin-top: 250px;
  margin-left: 25px;
  border-radius: 10px;
}

.buttonsessionActivity9 {
  height: 50px;
  width: 80px;
  border: 1px solid #007bff;
  background-color: green;
  color: wheat;
  border-radius: 10px;
  margin-top: 20px;
}
.buttonsessionActivity9:hover {
  background-color: red;
}

.buttonsessionActivity10 {
  height: 60px;
  width: 90px;
  border: 1px solid #007bff;
  background-color: green;
  color: wheat;
  border-radius: 10px;
}
.buttonsessionActivity10:hover {
  background-color: red;
}
.toolboxcss {
  font-size: xx-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #2b2b2a;
}
.container33 {
  display: inline-block;
  background-color: white;
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 5px;
  height: 30px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 30px;
  width: 140px;
  text-align: start;
}
.insbox {
  height: 60px;
  width: 60px;
  background-color: #007bff;
}
.NavbarApp {
  height: 30px;
  width: 100%;
  background-color: #011c2d;
  color: #d6f0fd;
}
.ybox {
  background-color: rgb(231, 211, 32);
  height: 300px;
  width: 2000px;
}
.iconify-icon-right {
  float: right;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;
}
.iconify-icon-left {
  float: left;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center;
}
.setpropertybox {
  width: 400px;
  height: 40px;
  background-color: rgb(247, 232, 66);
  font-size: medium;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.auto-width-box {
  display: inline-block;
  padding: 10px;
  border: 1px solid rgb(247, 232, 66);
  white-space: nowrap; /* Prevent text from wrapping */
  min-width: 10px; /* Set a minimum width for the box */
  max-height: 40px;
  background-color: white;
}
/* Basic button styles */
.greenifybutton {
  display: inline-block;
  padding: 0px;
  font-size: 16px; /* Adjust font size */
  background-color: #a0f871; /* Button background color */
  color: #fff; /* Text color */
  border: none; /* Remove the default button border */
  border-radius: 20px; /* Add rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  width: 220px;
  height: 40px;
  align-items: center;
  margin-left: 10px;
}

/* Button hover effect */
.greenifybutton:hover {
  background-color: #0056b3; /* Change background color on hover */
}

/* Button focus effect (keyboard navigation) */
.greenifybutton:focus {
  outline: none; /* Remove the default focus outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Add a subtle shadow on focus */
}

/* Button active effect (when clicked) */
.greenifybutton:active {
  background-color: #004499; /* Change background color when clicked */
}

.selectinpin {
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none;
  font-family: "Courier New", monospace;
  font-size: 10px;
  width: 30%;
  font-weight: normal;
  color: #000000;
  font-style: normal;
  border-radius: 5px;
  line-height: 6px;
}

.controlhead {
  background-color: #fff176;
  font-weight: 400;
  color: #000;
  font-family: "Gotham 4r", sans-serif, sans-serif;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  border-spacing: 4px;
  font-size: 14px;
  font-style: normal;
  border-spacing: 4px;
  border-bottom: 2px solid #000;
}

strong {
  font-family: "Gotham 5r", sans-serif;
  color: #000;
}

p {
  font-family: "Gotham 5r", sans-serif;
  color: #000;
}
.dropdown {
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none;
  font-family: "Courier New", monospace;
  font-size: 10px;
  width: auto;
  font-weight: normal;
  color: #000000;
  font-style: normal;
  border-radius: 5px;
  line-height: 10px;
  margin: 3px;
}

.getHtmlCodeBtn {
  float: right;
  background: #d6f0fd;
  padding: 0 10px;
  border-radius: 20px;
  font-size: 13px;
  text-transform: uppercase;
  margin-left: 5px;
  color: #666;
  margin-top: -26px;
}

.getHtmlCodeBtn:hover {
  background: #bee2f4;
}

.webcam-container {
  width: 500; /* Adjust the width as needed */
  height: 500; /* Adjust the height as needed */
  /* Add any additional styles for the container */
}

.webcam {
  width: 500; /* Adjust the width as needed */
  height: 500; /* Adjust the height as needed */
  /* Add any additional styles for the webcam */
}

